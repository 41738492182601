import React, {useEffect} from 'react';
import '../styles/popup.css';

const Popup = ({ shown, children, dismissible = true, onClose = (()=>{}) }) => {

    useEffect(() => {
        const handleEscape = (event) => {
            if (dismissible && event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscape);

        return () => document.removeEventListener('keydown', handleEscape);
    }, [onClose]);

    const handleClickOutside = (event) => {
        if (dismissible && event.target.classList.contains('popup-container')) {
            onClose();
        }
    };

    if (!shown) return null;


    return (
        <div className="popup-container" onClick={handleClickOutside}>
            <div className="popup-content">
                {dismissible && (
                    <button className="close-button" onClick={onClose}>
                        ✕
                    </button>
                )}
                <br></br>
                {children}
            </div>
        </div>
    );
};


export default Popup;
