import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { TestIcon, StopIcon, TinkerIcon, ReloadIcon, RunIcon } from "./icons";
import BluetoothUUIDs from "../BluetoothUUIDs";

const RobotConnection = forwardRef(({
                                        reloadPressed,
                                        connected,
                                        setConnected,
                                    }, ref) => {

    const [device, setDevice] = useState(null);
    const [statusVisible, setStatusVisible] = useState(true);
    const [connecting, setConnecting] = useState(false);

    const [reloadDisabled, setReloadDisabled] = useState(false);
    const [runDisabled, setRunDisabled] = useState(false);
    const [stopDisabled, setStopDisabled] = useState(true);
    const [tinkerDisabled, setTinkerDisabled] = useState(true);
    const [testDisabled, setTestDisabled] = useState(true);

    const [isRunning, setIsRunning] = useState(false);
    const [isTinkering, setIsTinkering] = useState(false);
    const [isTesting, setIsTesting] = useState(false);

    const [communicationCharacteristic, setCommunicationCharacteristic] = useState(null);
    const [executionCharacteristic, setExecutionCharacteristic] = useState(null);
    const [loggingCharacteristic, setLoggingCharacteristic] = useState(null);

    const [name, setName] = useState('');

    // Use useRef to persist subscribers across renders
    const subscribers = useRef({});

    useEffect(() => {
        return () => {
            if (device && device.gatt.connected) {
                device.gatt.disconnect();
            }
        };
    }, [device]);

    useImperativeHandle(ref, () => ({
        sendCommunication,
        executeCommand,
        flashStatusDot,
        addSubscriber,
        connected,
        setConnected,
        name,
        isRunning,
    }));

    function addSubscriber(id, subscriber) {
        subscribers.current[id] = subscriber;
    }

    function runPressed() {
        setRunDisabled(true);
        setStopDisabled(false);
        setIsRunning(true);
        for (const id in subscribers.current) {
            subscribers.current[id]('is-running', 'true');
        }
        sendCommunication("execute-target");
    }

    function stopPressed() {
        setStopDisabled(true);
        setRunDisabled(false);
        sendCommunication("stop-execution").then(() => {
            setIsRunning(false);
            for (const id in subscribers.current) {
                subscribers.current[id]('is-running', 'false');
            }
        });
    }

    function testPressed() {
        console.error("Not yet implemented.");
    }

    function tinkerPressed() {
        console.error("Not yet implemented.");
    }

    const connectToDevice = async () => {
        try {
            const device = await navigator.bluetooth.requestDevice({
                filters: [{ services: [BluetoothUUIDs.INTERACTIVE_SERVICE_UUID] }],
            });
            setConnecting(true);
            const server = await device.gatt.connect();
            setDevice(device);

            const interactiveService = await server.getPrimaryService(BluetoothUUIDs.INTERACTIVE_SERVICE_UUID);
            const comm = await interactiveService.getCharacteristic(BluetoothUUIDs.COMMUNICATION_CHARACTERISTIC_UUID);
            const exec = await interactiveService.getCharacteristic(BluetoothUUIDs.EXECUTION_CHARACTERISTIC_UUID);
            const log = await interactiveService.getCharacteristic(BluetoothUUIDs.LOGGING_CHARACTERISTIC_UUID);

            const receive_log = (event) => {
                const decoder = new TextDecoder();
                const [status, ...msgParts] = decoder.decode(event.target.value).split(",");
                const msg = msgParts.join(",");
                const msg_type = status === '0' ? "stdout" : "stderr";
                for (const id in subscribers.current) {
                    subscribers.current[id](msg_type, msg);
                }
            };
            log.addEventListener('characteristicvaluechanged', receive_log);
            log.startNotifications();

            setCommunicationCharacteristic(comm);
            setExecutionCharacteristic(exec);
            setLoggingCharacteristic(log);
            setConnected(true);
            setName(device.name);
            setConnecting(false);
        } catch (error) {
            setConnecting(false);
            console.error("Bluetooth connection error: ", error);
        }
    };

    function startHeartbeat(characteristic) {
        let last_receive = Date.now();

        characteristic.addEventListener('characteristicvaluechanged', (event) => {
            last_receive = Date.now();
        });

        const intervalId = setInterval(async () => {
            if ((Date.now() - last_receive) / 1000 > 2.5) { // Fixed the logic here
                setConnected(false);
                setCommunicationCharacteristic(null);
                setDevice(null);
                clearInterval(intervalId);
            } else {
                characteristic.writeValue(new Uint8Array([0x01])); // Corrected writeValue
            }
        }, 1000);
    }

    async function executeCommand(command) {
        return sendMessageToDevice(executionCharacteristic, command);
    }

    async function sendCommunication(message) {
        return sendMessageToDevice(communicationCharacteristic, message);
    }

    let messageQueue = Promise.resolve();
    const sendMessageToDevice = async (characteristic, message) => {
        let taskResolve, taskReject;
        const taskPromise = new Promise((resolve, reject) => {
            taskResolve = resolve;
            taskReject = reject;
        });
        messageQueue = messageQueue.then(() => {
            return new Promise((resolve, reject) => {
                if (!characteristic) {
                    flashStatusDot();
                    taskReject("Not connected");
                    // Always resolve to continue the queue
                    return Promise.resolve();
                }
                const onCharacteristicValueChanged = (event) => {
                    const decoder = new TextDecoder();
                    const [status, ...msgParts] = decoder.decode(event.target.value).split(',');
                    const msg = msgParts.join(',');
                    characteristic.removeEventListener('characteristicvaluechanged', onCharacteristicValueChanged);
                    if (status === '0') {
                        taskResolve(msg);
                    } else {
                        taskReject(msg);
                    }
                    resolve();
                };
                characteristic.addEventListener('characteristicvaluechanged', onCharacteristicValueChanged);

                characteristic.startNotifications().then(() => {
                    const encoder = new TextEncoder();
                    const msgArray = encoder.encode(message);
                    return characteristic.writeValue(msgArray);
                }).catch(error => {
                    characteristic.removeEventListener('characteristicvaluechanged', onCharacteristicValueChanged);
                    taskReject(error);
                    resolve();
                });
            });
        }).catch((error) => {
            console.error(error);
            return Promise.resolve();
        });
        return taskPromise;
    };

    const flashStatusDot = () => {
        const interval = 150;
        for (let i = 0; i < 3; i++) {
            setTimeout(() => setStatusVisible(false), i * 2 * interval);
            setTimeout(() => setStatusVisible(true), (i * 2 + 1) * interval);
        }
    };

    const disconnectFromDevice = () => {
        if (device && device.gatt.connected) {
            device.gatt.disconnect();
            setConnected(false);
            setDevice(null);
        }
    };

    return (
        <div style={styles.container}>
            <div style={{
                backgroundColor: connected ? 'green' : connecting ? 'orange' : 'red',
                ...styles.statusDot
            }} hidden={!statusVisible} />
            <div style={{
                backgroundColor: styles.container.backgroundColor,
                ...styles.statusDot
            }} hidden={statusVisible} />

            <div style={styles.textContainer}>
                <div style={styles.primaryText}>
                    {connected ? device.name : "Disconnected"}
                </div>
            </div>
            {!connected ? (
                <button style={styles.connectButton(connecting)} onClick={connectToDevice} disabled={connecting}>
                    Connect
                </button>
            ) : (
                <div style={styles.controlPanel}>
                    <button onClick={reloadPressed} disabled={reloadDisabled} style={styles.button} title="Reload">
                        <ReloadIcon disabled={reloadDisabled} />
                    </button>
                    <button onClick={testPressed} disabled={testDisabled} style={styles.button} title="Test">
                        <TestIcon disabled={testDisabled} />
                    </button>
                    <button onClick={tinkerPressed} disabled={tinkerDisabled} style={styles.button} title="Tinker">
                        <TinkerIcon disabled={tinkerDisabled} />
                    </button>
                    <button onClick={stopPressed} disabled={stopDisabled} style={styles.button} title="Stop">
                        <StopIcon disabled={stopDisabled} />
                    </button>
                    <button onClick={runPressed} disabled={runDisabled} style={styles.button} title="Run">
                        <RunIcon disabled={runDisabled} />
                    </button>
                </div>
            )}
        </div>
    );
});

const styles = {
    container: {
        flex: 1,
        padding: '10px',
        backgroundColor: 'rgba(50, 50, 50, 0.8)',
        border: '1px solid rgba(50, 50, 50, 0.8)',
        borderRadius: '15px',
        width: '250px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
        transition: 'all 0.3s ease-in-out',
    },
    statusDot: {
        marginLeft: 'auto',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
    },
    textContainer: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    primaryText: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    connectButton: (disabled) => ({
        padding: '10px 20px',
        fontSize: '16px',
        cursor: disabled ? 'default' : 'pointer',
        backgroundColor: disabled ? '#78a4af' : '#61dafb',
        color: '#282c34',
        border: 'none',
        borderRadius: '10px',
        transition: 'background-color 0.2s ease, transform 0.2s ease',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        outline: 'none',
    }),
    controlPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '10px',
    },
    button: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: '0 10px',
        outline: 'none',
        transition: 'transform 0.1s ease',
    },
    buttonPressed: {
        transform: 'scale(0.9)',
    },
};

export default RobotConnection;
