import React, { useState } from 'react';

const GlowButton = ({ text, onClick, gradient = "linear-gradient(45deg, purple, violet, magenta, white)", shadow = "rgba(128, 0, 128, 0.8)" }) => {
    const [isHovered, setIsHovered] = useState(false);

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
    };

    const wrapperStyle = {
        padding: '4px',
        borderRadius: '50px',
        background: gradient,
        backgroundSize: '200% 200%',
        animation: 'rotateGradient 3s linear infinite',
        display: 'inline-block',
    };

    const buttonStyle = {
        backgroundColor: 'black',
        color: 'white',
        border: 'none',
        borderRadius: '50px',
        padding: '10px 50px',
        fontSize: '16px',
        cursor: 'pointer',
        outline: 'none',
        transition: 'box-shadow 0.3s ease-in-out',
        width: '300px',
        height: '50px',
        boxShadow: isHovered
            ? '0 10px 20px 5px ' + shadow
            : '0 5px 10px 3px ' + shadow,
    };

    const rotateGradient = `
        @keyframes rotateGradient {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
        }
    `;

    // Inject the keyframes into the document's style
    if (!document.getElementById('glow-button-styles')) {
        const styleSheet = document.createElement('style');
        styleSheet.id = 'glow-button-styles';
        styleSheet.innerText = rotateGradient;
        document.head.appendChild(styleSheet);
    }

    return (
        <div style={containerStyle}>
            <div style={wrapperStyle}>
                <button
                    style={buttonStyle}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={onClick}
                >
                    {text}
                </button>
            </div>
        </div>
    );
};

export default GlowButton;
