import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyC0U_hhlqndhdFiofe6q2IE1d_e-_iVqZw",
    authDomain: "cyberonics-c225b.firebaseapp.com",
    projectId: "cyberonics-c225b",
    storageBucket: "cyberonics-c225b.appspot.com",
    messagingSenderId: "349568015618",
    appId: "1:349568015618:web:032da7d4e7474528f8361b",
    measurementId: "G-V75YLD6CED"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {app, analytics}