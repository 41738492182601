import React from "react";
import GlowButton from "../components/GlowButton";
import GlowField from "../components/GlowField";
import {useEffect, useState} from "react";

import {app, analytics} from "../firebase";
import {
    getAuth,
    createUserWithEmailAndPassword,
    updateProfile,
    signInWithEmailAndPassword,
    sendEmailVerification,
    onAuthStateChanged
} from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import Popup from "../components/Popup";

const auth = getAuth();
const db = getFirestore();


export default function Auth() {

    const [state, setState] = useState('start');
    const [btnText, setBtnText] = useState('Continue');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [errorPopupShown, setErrorPopupShown] = useState(false);
    const [popupText, setPopupText] = useState('');


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && user.emailVerified) {
                window.location = '/console';
            }
        });
        return () => unsubscribe();
    }, []);

    function showMessage(text) {
        setPopupText(text);
        setErrorPopupShown(true);
    }

    var verification_poll = null

    const handleClick = async () => {
        switch (state) {
            case 'start':
                try {
                    const response = await fetch('https://cyberapi.skyler.cc/check_email', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email }),
                    });

                    const data = await response.json();
                    if (data.action) {
                        setState(data.action);
                        switch (data.action) {
                            case "signup":
                                setBtnText("Sign up")
                                break;
                            case "login":
                                setBtnText("Login")
                                break;
                            case "request-info":
                                setBtnText("Get info")
                                break;
                        }
                    } else {
                        showMessage(data.error);
                    }
                } catch (error) {
                    showMessage("An error occurred while trying to submit the form.");
                }
                break;
            case "signup":
                try {
                    const cred = await createUserWithEmailAndPassword(auth, email, password);
                    const user = cred.user;
                    await updateProfile(user, {
                        displayName: `${firstName} ${lastName}`,
                    });
                    await setDoc(doc(db, 'users', user.uid), {
                        firstName,
                        lastName,
                        email,
                    });
                    sendEmailVerification(auth.currentUser).then(()=> {
                        setState('verify-email');
                        setBtnText('Change email');
                        verification_poll = setInterval(() => {
                            auth.currentUser.reload().then(() => {
                                if (auth.currentUser.emailVerified) {
                                    clearInterval(verification_poll);
                                    window.location = '/console';
                                }
                            });
                        }, 1000);
                    });
                }
                catch(error) {
                    let errorMessage = '';

                    switch (error.code) {
                        case 'auth/email-already-in-use':
                            errorMessage = 'This email address is already in use. Please use a different email.';
                            break;
                        case 'auth/invalid-email':
                            errorMessage = 'The email address is not valid. Please enter a valid email.';
                            break;
                        case 'auth/weak-password':
                            errorMessage = 'The password is too weak. Please enter a stronger password.';
                            break;
                        default:
                            errorMessage = 'An error occurred. Please try again.';
                            break;
                    }
                    showMessage(errorMessage);
                }
                break;

            case 'login':
                try {
                    const cred = await signInWithEmailAndPassword(auth, email, password);
                    if (cred.user.emailVerified) {
                        window.location = '/console';
                    } else {
                        setState("verify-email");
                    }
                }
                catch(error) {
                    let errorMessage = '';
                    switch (error.code) {
                        case 'auth/wrong-password':
                            errorMessage = 'The password is incorrect. Please try again.';
                            break;
                        case 'auth/user-not-found':
                            errorMessage = 'No user found with this email address. Please check and try again.';
                            break;
                        case 'auth/invalid-email':
                            errorMessage = 'The email address is not valid. Please enter a valid email.';
                            break;
                        case 'auth/too-many-requests':
                            errorMessage = 'Too many login attempts. Please try again later.';
                            break;
                        default:
                            errorMessage = 'An error occurred. Please try again.';
                            break;
                    }
                    showMessage(errorMessage);
                }
                break;

            case 'verify-email':
                auth.currentUser.delete().then(() => {
                    setState('signup');
                    setBtnText('Sign up');
                })
                break;

            case 'request-info':
                const response = await fetch('https://cyberapi.skyler.cc/request_info', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        firstName: firstName,
                        lastName: lastName
                    }),
                });
                if (response.ok) {
                    showMessage("Thanks! We'll reach out soon.");
                    window.location = '/';
                }
                else {
                    const data = await response.json();
                    if (data.error) {
                        showMessage(data.error);
                    }
                    else {
                        showMessage("An unknown error occurred.");
                    }

                }
        }

    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                handleClick().then(r => null);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [email, password, firstName, lastName, state]);

    return <>
        <h1>Welcome, user</h1>
        <div style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            width: "100%",
            maxWidth: "300px"
        }}>
            {(state !== 'verify-email') && (
                <GlowField placeholder={"Email"} value={email} onChange={setEmail}></GlowField>
            )}

            {(state === 'signup' || state === 'login') && (
                <GlowField placeholder={"Password"} value={password} onChange={setPassword} type={"password"}></GlowField>
            )}

            {(state === 'signup' || state === 'request-info') && (
                <>
                    <GlowField placeholder={"First name"} value={firstName} onChange={setFirstName}></GlowField>
                    <GlowField placeholder={"Last name"} value={lastName} onChange={setLastName}></GlowField>
                </>
            )}

            {(state === 'verify-email') && (
                <>
                    <h2>Check your inbox!</h2>
                    <i><h3>We sent a verification email to {email}</h3></i>
                </>
            )}

            <GlowButton text={btnText} gradient={"linear-gradient(45deg, cyan, white)"}
                        shadow={"rgba(0, 238, 255, 0.8)"} id={"btn"} onClick={handleClick}></GlowButton>
            <Popup shown={errorPopupShown} onClose={() => {setErrorPopupShown(false)}}>
                <h3>{popupText}</h3>
            </Popup>
        </div>
    </>

}
