import React, {useEffect, useState, useRef} from 'react';
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import ConsoleNavBar from "../components/ConsoleNavBar";
import Terminal from "../components/Terminal";

const auth = getAuth();

const Console = () => {
    const [cmd, setCmd] = useState('');




    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [selectedTab, setSelectedTab] = useState('Dashboard');

    const [connected, setConnected] = useState(false)
    const robotConnection = useRef(null);

    const terminal = useRef(null);
    const [visibleTab, setVisibleTab] = useState("command");

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                if (user.emailVerified) {
                    setUser(user);
                } else {
                    window.location = '/auth';
                }
            } else {
                window.location = '/auth';
            }
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    function receiveMessage(data) {
        console.log(data);
    }
    const sendMessage = (message) => {
        if (robotConnection.current) {
            robotConnection.current.sendMessage(message);
        }
    };




    if (loading) {
        return <h1>Loading...</h1>;
    }

    if (user) {
        return (
            <div style={{height: '100vh', overflow: 'hidden'}}>
                <ConsoleNavBar
                    user={user}
                    robotConnection={robotConnection}
                    connected={connected}
                    setConnected={setConnected}
                />

                <Terminal
                    ref={terminal}
                    visibleTab={"execute"}
                    robotConnection={robotConnection}
                    onCommandExecuted={(cmd) => {console.log(`Command: ${cmd}`)}}
                />

                {/*<ConsoleSideBar*/}
                {/*    tabNames={['Dashboard','Analytics', 'Project Settings']}*/}
                {/*    selectedTab={selectedTab}*/}
                {/*    setSelectedTab={setSelectedTab}*/}
                {/*/>*/}
                {/*<div style={{*/}
                {/*    marginTop: "200px",*/}
                {/*    marginLeft: "auto",*/}
                {/*    marginRight: "auto",*/}
                {/*    display: "block",*/}
                {/*    width: "100%",*/}
                {/*    maxWidth: "300px"*/}
                {/*}}>*/}
                {/*    <GlowField placeholder={"Command"} value={cmd} onChange={setCmd}></GlowField>*/}
                {/*    <GlowButton onClick={() => {sendMessage(cmd)}} text={"Execute"}></GlowButton>*/}
                {/*</div>*/}
            </div>
        );
    }
};

export default Console;
