import React from 'react';

const GlowCheckbox = ({ checked, onChange, label }) => {
    const checkboxContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    };

    const checkboxStyle = {
        appearance: 'none',
        width: '20px',
        height: '20px',
        borderRadius: '5px',
        border: '2px solid #00f0ff',
        backgroundColor: 'transparent',
        position: 'relative',
        transition: 'box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out',
        outline: 'none',
        marginRight: '10px',
        cursor: 'pointer',
    };

    const checkedStyle = {
        backgroundColor: '#00f0ff',
        boxShadow: '0 0 15px #00f0ff',
    };

    const handleCheckboxChange = (e) => {
        onChange && onChange(e.target.checked);
    };

    return (
        <div style={checkboxContainerStyle}>
            <input
                type="checkbox"
                style={{
                    ...checkboxStyle,
                    ...(checked ? checkedStyle : {}),
                }}
                checked={checked}
                onChange={handleCheckboxChange}
                onFocus={(e) => e.target.style.boxShadow = '0 0 15px #00f0ff'}
                onBlur={(e) => !checked && (e.target.style.boxShadow = 'none')}
            />
            {label && <label style={{ color: '#fff', fontSize: '18px' }} onClick={() => {onChange(!checked)}}>{label}</label>}
        </div>
    );
};

export default GlowCheckbox;
