import React from 'react';

const GlowField = ({ placeholder, value, onChange, type = "text" }) => {

    const inputStyle = {
        padding: '15px',
        fontSize: '18px',
        border: '2px solid #00f0ff',
        backgroundColor: 'transparent',
        color: '#fff',
        outline: 'none',
        transition: 'box-shadow 0.3s ease-in-out',
        borderRadius: '10px',
        width: '100%',
        minWidth: "200px",
        maxWidth: "300px",
        boxSizing: "border-box",
        marginBottom: "10px",
        borderColor: '#00f0ff'
    };

    const focusedStyle = {
        boxShadow: '0 0 15px #00f0ff',
    };

    return (
        <input
            type={type}
            style={{
                ...inputStyle,
                ...(value ? focusedStyle : {}),
            }}
            placeholder={placeholder}
            value={value || ''}
            onChange={(e) => onChange && onChange(e.target.value)}
            onFocus={(e) => e.target.style.boxShadow = '0 0 15px #00f0ff'}
            onBlur={(e) => e.target.style.boxShadow = 'none'}
        />
    );
};

export default GlowField;
