
const BluetoothUUIDs = Object.freeze({
    DEVICE_SERVICE_UUID: '155aeaa2-4277-48da-9448-3a4cb8671522',
    INTERACTIVE_SERVICE_UUID: 'a3fbe4df-f5a7-4daf-93f5-463222da42af',
    EXECUTION_CHARACTERISTIC_UUID: 'd8d434bb-e6ae-4958-829e-db98365ee614',
    COMMUNICATION_CHARACTERISTIC_UUID: 'e88352cb-896c-40a9-a8ea-2626dbc540b8',
    HEARTBEAT_CHARACTERISTIC_UUID: 'ce469a50-d65a-460a-82e5-3a7dc5fb16d6',
    LIFE_CYCLE_CHARACTERISTIC_UUID: 'd13725ec-e46c-45dd-bfd7-b84b6234164c',
    LOGGING_CHARACTERISTIC_UUID: 'c8259370-9361-431d-860d-7789ad2be10f'
});

export default BluetoothUUIDs