import React from "react";

export const ReloadIcon = ({ disabled }) => (
    <svg fill={disabled ? "#a4a4a4": "#eba834"} xmlns="http://www.w3.org/2000/svg" width="24" height="24"
         className="bi bi-arrow-down-square-fill" viewBox="0 0 16 16">
        <path
            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.5 4.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5a.5.5 0 0 1 1 0"/>
    </svg>
);

export const TestIcon = ({disabled}) => (
    <svg fill={disabled ? "#a4a4a4": "#d534eb"} height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 452.581 452.581">
        <g id="XMLID_807_">
            <g>
                <path d="M428.58,371.32c-2.344-3.772-128.738-207.153-133.259-214.428V30h4.195c8.284,0,15-6.716,15-15s-6.716-15-15-15
			c-6.162,0-140.193,0-146.453,0c-8.284,0-15,6.716-15,15s6.716,15,15,15h4.196v126.893C155.838,159.179,28.565,363.974,24,371.32
			c-21.979,35.367,3.516,81.261,45.174,81.261h314.232C425.046,452.581,450.569,406.702,428.58,371.32z M184.999,169.092
			c1.477-2.377,2.26-5.12,2.26-7.918V30h78.062v131.174c0,2.798,0.783,5.541,2.26,7.918l22.107,35.572H162.892L184.999,169.092z
			 M383.406,422.581H69.173c-18.156,0-29.278-20.005-19.693-35.425l94.768-152.492h164.083l94.768,152.492
			C412.681,402.573,401.567,422.581,383.406,422.581z"/>
            </g>
        </g>
    </svg>
);

export const TinkerIcon = ({disabled}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={disabled ? "#a4a4a4": "#3477eb"}  className="bi bi-tools"
         viewBox="0 0 16 16">
        <path
            d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3q0-.405-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708M3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z"/>
    </svg>
);

export const StopIcon = ({disabled}) => (
    <svg
        width="24" height="24" viewBox="0 0 24 24"
        fill={disabled ? '#a4a4a4' : '#d53d3d'}
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Square path */}
        <rect x="5" y="5" width="14" height="14"/>
    </svg>
);

export const RunIcon = ({disabled}) => (
    <svg
        width="24" height="24" viewBox="0 0 24 24"
        fill={disabled ? '#a4a4a4' : '#4eab2d'}
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Play button path */}
        <polygon points="6,4 20,12 6,20"/>
    </svg>
);
