import React, {useState} from 'react';

export const NavBar = ({ children }) => {
    const navBarStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'rgba(50, 50, 50, 0.8)',
        borderRadius: '15px',
        margin: '20px auto',
        padding: '10px 20px',
        width: '90%',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    };

    const navCellStyle = {
        color: '#61dafb',
        fontFamily: '"Pixelify Sans", sans-serif',
        textAlign: 'center'
    };

    return (
        <nav style={navBarStyle}>
            {React.Children.map(children, (child, index) => (
                <div key={index} style={navCellStyle}>
                    {child}
                </div>
            ))}
        </nav>
    );
};


export const DropdownNavCell = ({ content, options, onSelect, searchable = false, disabled = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().startsWith(searchQuery.toLowerCase())
    );

    const dropdownCellStyle = {
        position: 'relative',
        display: 'inline-block',
        cursor: disabled ? 'not-allowed' : 'pointer',
        color: disabled ? 'grey' : '#eca7ff',
        fontSize: '18px',
        transition: 'color 0.2s ease-in-out',
        margin: '15px',
        minWidth: '100px',
        width: 'auto',
        borderRadius: '10px',
        padding: '10px',
        backgroundColor: '#3a3d41',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };

    const dropdownMenuStyle = {
        position: 'absolute',
        top: '100%',
        left: 0,
        backgroundColor: '#090a15',
        border: '1px solid #61dafb',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
        zIndex: 999,
        maxHeight: '200px',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    };

    const hideScrollBar = {
        '&::WebkitScrollbar': {
            display: 'none',
        },
    };

    const dropdownItemStyle = {
        padding: '10px 20px',
        cursor: 'pointer',
        color: '#61dafb',
        transition: 'background-color 0.1s ease-in-out',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    };

    const searchBarStyle = {
        padding: '10px',
        backgroundColor: '#3a3d41',
        border: 'none',
        outline: 'none',
        color: '#61dafb',
        width: '100%',
        fontSize: '16px',
        boxSizing: 'border-box',
        marginBottom: '10px',
    };

    const handleSelect = (id) => {
        onSelect(id);
        setIsOpen(false);
    };

    const handleMouseEnter = () => {
        if (!disabled) setIsOpen(true);
    };

    const handleMouseLeave = () => {
        if (!disabled) setIsOpen(false);
    };

    return (
        <div
            style={dropdownCellStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {content}
            {isOpen && !disabled && (
                <div style={{ ...dropdownMenuStyle, ...hideScrollBar }}>
                    {searchable && (
                        <input
                            type="text"
                            placeholder="Search..."
                            style={searchBarStyle}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    )}
                    {filteredOptions.map((option) => (
                        <div
                            key={option.id}
                            style={dropdownItemStyle}
                            onClick={() => handleSelect(option.id)}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(97, 218, 251, 0.5)'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#090a15'}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};



